<template>
   <slot name="recordcount" :dataObject="dataObject" :refreshRowCount="refreshRowCount">
        <div v-if="dataObject?.hasPagedData && dataObject.pagedData.enabled" class="px-2 d-flex" style="white-space: nowrap;">
            <Paginator :dataObject="dataObject"/>
        </div>
        <div v-else class="px-2 d-flex" style="white-space: nowrap;">
                <div v-if="isRowCountLoading" class="spinner-border spinner-border-sm ms-1 align-self-center" role="status">
                    <span class="sr-only"></span>
                </div>

                <template v-else-if="infoItemsType === InfoItemsType.ArrayGrid">
                    <b v-if="filterObject.appliedFilterString" class="align-self-center">
                        <span :title="$t('Filtered Rows')">{{number.format(filteredRowCount, '1 234')}}</span> <span :title="$t('Total Rows')">({{number.format(rowCount, '1 234')}})</span>
                    </b>
                    <b v-else class="align-self-center">
                        <span :title="$t('Total Rows')">{{number.format(rowCount, '1 234')}}</span>
                    </b>
                </template>

                <b v-else-if="dataObject?.treeify?.enabled" class="align-self-center">
                    <span :title="$t('Loaded Rows')">{{number.format(dataObject.data.length, '1 234')}}</span> <span :title="$t('Total Rows')">({{number.format(dataObject.dataHandler.clientSideData.length, '1 234')}})</span>
                </b>

                <b v-else-if="rowCount == null"  class="align-self-center">
                    <span v-if="dataObject.current?.index !== undefined" :title="$t('Current Row')">{{number.format(dataObject.current?.index+1, '1 234')}} / </span>
                    <button class="btn btn-sm btn-link" :title="$t('Load row count')" @click="refreshRowCount" style="font-size: 0.75rem; padding-left: 0px;">
                        <i class="bi bi-question-circle"></i>
                    </button>
                </b>
                <b v-else  class="align-self-center">
                    <span v-if="dataObject.current?.index !== undefined" :title="$t('Current Row')">{{number.format(dataObject.current?.index+1, '1 234')}} / </span>
                    <span :title="$t('Row Count')">{{number.format(rowCount, '1 234')}}</span>
                </b>
        </div>
    </slot>

    <button v-if="!noRefreshButton" class="btn btn-link btn-sm" @click="refreshData"><i class="bi bi-arrow-clockwise"></i></button>
    <DataGridMenuDropdown v-if="_dataGridControl"/>
    <o-filter-list-dropdown v-if="_dataGridControl && dataObject" :data-object="dataObject"></o-filter-list-dropdown>

    <OFilterString v-if="!noFilterString" :filterObject="filterObject" class="text-truncate mt-1" style="display: flex; align-items: baseline;">
        <template #clear="{clear}">
            <button class="btn btn-sm btn-link px-1 pt-0 ms-2 text-danger" @click="clear()" :title="$t('Clear current filter')">
                <i class="bi bi-x-circle-fill"></i>
            </button>
        </template>
    </OFilterString>
    <button v-else-if="noFilterString && filterObject.filterString" class="btn btn-sm btn-link" @click="filterObject.clear()" :title="$t('Clear current filter')">
        <i class="bi bi-x-circle-fill"></i>
    </button>

    <OSaveFilter :data-object="dataObject" class-name="d-flex" 
        v-if="dataObject && dataObject.filterObject.filterString"
        save-class="btn btn-link btn-sm ms-auto"
        save-as-class="btn btn-link btn-sm ms-1 text-nowrap"/>

    <ODropdown v-if="dataObject?.filterObject?.persistentFilterId">
        <template #default="{target, open}">
            <button :ref=target class="btn btn-sm btn-link py-0" @click="dataObject?.filterObject.persistentFilterEnabled && dataObject?.filterObject.appliedFilterString? open() : togglePersistentFilter()" 
                :title="$t('Set filter as default')"
                :style="dataObject.filterObject.persistentFilterEnabled ? 'color: var(--o365-active-filter)!important;' : ''">
                <i v-if="dataObject.filterObject.persistentFilterEnabled" class="bi bi-person-check-fill"></i>
                <i v-else class="bi bi-person-check-fill"></i>    
            </button>
        </template>
        <template #dropdown="{container, close}">
            <div class="dropdown-menu show" :ref="container">
                <button class="dropdown-item" :title="$t('Set current filter as default')" @click="() => {togglePersistentFilter(); close()}">
                    {{$t('Set Applied Filter As Default')}}
                </button>
                <button class="dropdown-item" :title="$t('Clear default filter')" @click="() => {togglePersistentFilter(true); close()}">
                    {{$t('Clear Default Filter')}}
                </button>
            </div>
        </template>
    </ODropdown>
        
    <div v-if="selectionControl?.selectedRows?.length && !selectionControl?.selectAllLoading" class="d-flex align-items-baseline text-truncate mt-1 text-truncate mt-1">
        <label class="text-truncate">
            {{selectionControl.selectedUniqueKeys?.size ?? selectionControl.selectedDataItems?.size}} {{$t('row(s) selected')}}
        </label>
        <button v-if="!noSelectClear" class="btn btn-sm btn-link px-1 pt-0" @click.stop="() => { dataObject ? dataObject.selectionControl.selectAll(false) : selectionControl.selectAll(false) }">{{$t('Clear')}}</button>
    </div>
    <slot name="actionsEnd"></slot>
    <OLayoutStatus v-if="showLayoutStatus" />
</template>

<script setup lang="ts">
import type DataObject from 'o365.modules.DataObject.ts';
import type DataGridContorl from 'o365.controls.DataGrid.ts';

import OFilterListDropdown from 'o365.vue.components.FilterListDropdown.vue';
import OSaveFilter from 'o365.vue.components.SaveFilter.vue';
import OLayoutStatus from 'o365.vue.components.LayoutStatus.vue';
import OFilterString from 'o365.vue.components.FilterString.vue';
import { dataGridControlKey } from 'o365.modules.vue.injectionKeys.js';
import number from 'o365.modules.utils.number.js';
import DataGridMenuDropdown from 'o365.vue.components.DataGrid.GridMenuDropdown.vue';
import useAsyncComponent from 'o365.vue.composables.AsyncComponent.ts';
import ODropdown from 'o365.vue.components.DropDown.vue';
import { inject, computed } from 'vue';

const Paginator = useAsyncComponent('o365.vue.components.Paginator.vue');

const props = defineProps<{
    dataObject?: DataObject,
    layoutStatus?: boolean,
    noSelectClear?: boolean,
    noFilterString?: boolean,
    noRefreshButton?: boolean,
    dataGridControl?: DataGridContorl,
}>();

enum InfoItemsType {
    DataObject,
    DataObjectGrid,
    ArrayGrid,
    Invalid
};

const injectedDataGridControl = inject(dataGridControlKey, null);

const _dataGridControl = computed(() => {
    return props.dataGridControl ?? injectedDataGridControl?.value
});

/** Current info items type */
const infoItemsType = computed(() => {
    if (_dataGridControl.value) {
        if (_dataGridControl.value.dataObject) {
            return InfoItemsType.DataObjectGrid;
        } else {
            return InfoItemsType.ArrayGrid;
        }
    } else if (props.dataObject) {
        return InfoItemsType.DataObject
    } else {
        return InfoItemsType.Invalid;
    }
});

const isRowCountLoading = computed(() => {
    switch(infoItemsType.value) {
        case InfoItemsType.DataObjectGrid:
        case InfoItemsType.ArrayGrid:
            return _dataGridControl.value!.state.isRowCountLoading
        default:
            return props.dataObject.isRowCountLoading
    }
});

const rowCount = computed(() => {
    switch(infoItemsType.value) {
        case InfoItemsType.DataObjectGrid:
            if (_dataGridControl.value!.dataObject!.rowCount && _dataGridControl.value!.dataObject.batchDataEnabled) {
                return _dataGridControl.value!.dataObject!.rowCount + _dataGridControl.value!.dataObject.batchData.rowCount;
            } else {
                return _dataGridControl.value!.dataObject!.rowCount;
            }
        case InfoItemsType.ArrayGrid:
            return _dataGridControl.value!.utils.rowCount;
        default:
            if (props.dataObject.rowCount && props.dataObject.batchDataEnabled) {
                return props.dataObject.rowCount + props.dataObject.batchData.rowCount;
            } else {
                return props.dataObject.rowCount
            }
    }
});

const filteredRowCount = computed(() => {
    switch(infoItemsType.value) {
        case InfoItemsType.DataObjectGrid:
            return _dataGridControl.value!.dataObject!.filteredRowCount;
        case InfoItemsType.ArrayGrid:
            return _dataGridControl.value!.utils.filteredRowCount;
        default:
            return props.dataObject.filteredRowCount
    }
});

const filterObject = computed(() => {
    switch (infoItemsType.value) {
        case InfoItemsType.DataObjectGrid:
        case InfoItemsType.ArrayGrid:
            return _dataGridControl.value!.filterObject;
        default:
            return props.dataObject.filterObject
    }
});

const selectionControl = computed(() => {
    switch (infoItemsType.value) {
        case InfoItemsType.DataObjectGrid:
        case InfoItemsType.ArrayGrid:
            return _dataGridControl.value!.selectionControl;
        default:
            return props.dataObject.selectionControl;
    }
});

const showLayoutStatus = computed(() => {
    return (props.dataObject?.layoutManager && (props.layoutStatus ?? _dataGridControl.value));
});


async function refreshData() {
    if (_dataGridControl.value?.load && props.dataObject?.treeify == null) {
        _dataGridControl.value.load();
    } else if (props.dataObject.treeify && props.dataObject.treeify.enabled) {
        // Load new data into treeify
        props.dataObject.treeify.disable();
        if (props.dataObject.filterObject.appliedFilterString != null) {
            // Need to apply filter after getting new data only
            const prevFilterString = props.dataObject.recordSource.filterString;
            props.dataObject.recordSource.filterString = null;
            await props.dataObject.load()
            props.dataObject.recordSource.filterString = prevFilterString;
        } else {
            await props.dataObject.load();
        }
        props.dataObject.treeify.enable()
        await props.dataObject.load();
    } else {
        props.dataObject.load();
    }
}

const showNewRecordButton = computed(() => {
    return props.dataObject?.allowInsert && !_dataGridControl.value?.disableBatchRecords;
});

function togglePersistentFilter(pClear = false) {
    if (props.dataObject?.filterObject) {
        if (props.dataObject.filterObject.appliedFilterString && !pClear) {
            props.dataObject.filterObject.setPersistentFilter();
            props.dataObject.filterObject.persistentFilterEnabled = true;
        } else {
            props.dataObject.filterObject.removePersistentFilter();
            props.dataObject.filterObject.persistentFilterEnabled = false;
        }
        // if (props.dataObject.filterObject.persistentFilterEnabled) {
            // props.dataObject.filterObject.persistentFilterEnabled = false;
            // props.dataObject.filterObject.removePersistentFilter();
        // } else {
            // props.dataObject.filterObject.persistentFilterEnabled = true;
            // props.dataObject.filterObject.setPersistentFilter();
        // }
    }
}

async function createNewRecord() {
    _dataGridControl.value.enableBatchRecords();
}

async function refreshRowCount() {
    props.dataObject?.recordSource.loadRowCounts({
        timeout: 30000
    });
}
</script>